import { useState } from "react";
import { withRouter } from "react-router-dom";
import Logo from "./Logo";
import MenuTray from "./MenuTray";

function Header(props) {
  const [isOpen, setTray] = useState(false);

  return (
    <header className="layout px-4 md:px-8 flex justify-between items-center pt-8 md:pt-10 lg:pt-12 pb-10 md:pb-12 lg:pb-24">
      <div onClick={() => props.history.push("/")}>
        <Logo></Logo>
      </div>
      <nav className="items-center hidden md:flex">
        <div
          className="ml-8 type-ui cursor-pointer"
          onClick={() => props.history.push("/terms-of-service")}
        >
          Terms of service
        </div>
        <div
          className="ml-8 type-ui cursor-pointer"
          onClick={() => props.history.push("/privacy-policy")}
        >
          Privacy Policy
        </div>

        <a href="https://soft-spam-8vc-prod.herokuapp.com/accounts/5c719ecf-3902-4cfb-a4ce-bafaeb01a68f/metrics?period=week">
          <button className="button-lg type-ui  bg-wall-600 text-white ml-8">
            Login
          </button>
        </a>
      </nav>

      <MenuTray isOpen={isOpen} setTray={setTray} search={props.search}>
        <a className="type-ui mb-12" href="/">
          {" "}
          <Logo></Logo>
        </a>
        <div
          className="mr-5 mb-4 type-h3"
          onClick={() => props.history.push("/terms-of-service")}
        >
          Terms of Service
        </div>
        <div
          className="mr-5 mb-4 type-h3"
          onClick={() => props.history.push("/privacy-policy")}
        >
          Privacy policy
        </div>

        <a href="https://soft-spam-8vc-prod.herokuapp.com/accounts/5c719ecf-3902-4cfb-a4ce-bafaeb01a68f/metrics?period=week">
          <button className="button-lg type-ui w-full bg-wall-600 text-white ">
            Login
          </button>
        </a>
      </MenuTray>
    </header>
  );
}

export default withRouter(Header);
