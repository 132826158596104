import React, { useEffect } from "react";
import Container from "./Container";
import Header from "./Header";
import Footer from "./Footer";
import Section from "./Section";
import SingleColumn from "./SingleColumn";

export default function Terms() {
  useEffect(() => {
    console.log("ran use effect");
  });

  return (
    <Container>
      <Header />
      <SingleColumn>
        <Section className=" md:flex">
          {" "}
          <iframe
            id="4b2db653-b2ce-44de-9b6e-99eea89a08ab"
            title="terms of service"
            src="https://app.termly.io/embed/terms-of-use/4b2db653-b2ce-44de-9b6e-99eea89a08ab"
            class="Iframe-iframe-ad8182"
            scrolling="no"
            allowfullscreen=""
            style={{ height: "9000px", opacity: 1 }}
            frameborder="0"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </Section>
      </SingleColumn>

      <Footer></Footer>
    </Container>
  );
}
