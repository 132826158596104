import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Home from "./Home";
import Terms from "./Terms";
import Privacy from "./Privacy";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/terms-of-service" component={Terms} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
