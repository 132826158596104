import React from 'react'

export default function Logo() {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<rect opacity="0.2" width="67" height="9" fill="#111111"/>
<rect opacity="0.4" y="9" width="67" height="9" fill="#111111"/>
<rect opacity="0.6" y="18" width="67" height="9" fill="#111111"/>
<rect opacity="0.8" y="27" width="67" height="9" fill="#111111"/>
<rect y="36" width="67" height="9" fill="#111111"/>
</g>

</svg>

    )
}
