import { withRouter } from "react-router-dom";
import SingleColumn from "./SingleColumn";
import Section from "./Section";

function Footer(props) {
  return (
    <footer className="bg-wall-100 w-full mt-48 flex justify-center">
      <SingleColumn>
        <Section className="flex flex-col md:flex-row">
          <div className="md:w-1/3">
            <div
              className="type-bold text-wall-500"
              onClick={() => props.history.push("/privacy-policy")}
            >
              Privacy Policy
            </div>
          </div>
          <div className="md:w-1/3">
            <div className="type-bold text-wall-500">Terms of Service</div>
          </div>
          <div className="md:w-1/3">
            <div>
              <a
                href={"mailto:hi@thewhitelist.dev"}
                className="type-bold text-wall-500"
              >
                {"hi@thewhitelist.dev"}
              </a>
            </div>
          </div>
        </Section>
      </SingleColumn>
    </footer>
  );
}

export default withRouter(Footer);
