import React from "react";
import Container from "./Container";
import Header from "./Header";
import Footer from "./Footer";
import Section from "./Section";
import SingleColumn from "./SingleColumn";

export default function Home() {
  return (
    <Container>
      <Header />
      <SingleColumn>
        <Section className=" md:flex">
          <div className="bg-wall-600 w-full port-hero-card-height rounded-3xl flex">
            <div className="pt-8 p-8 md:pt-20 md:pl-12 md:pr-12 w-full">
              <div className="pb-8">
                <h2 className="p-0 m-0 pb-2 leading-none text-wall-400">
                  Introducing
                </h2>
                <h2 className="text-white p-0 m-0 leading-none">
                  The whitelist: A private email management application.
                </h2>
              </div>
              <h4 className="pb-8 text-wall-400">
                Can you go through 100 emails an hour and remain productive?{" "}
                <br />
                <br />
                Thewhitelist app will enhance email management. Through custom
                filters and auto labeling, you or your delegates can be
                presented with relevant emails only. For executives that need to
                keep in touch with their entire network and be able to give the
                right amount of attention to each email. Being able to
                automatically delegate emails to EA’s can assist with marking
                emails that require attention so you don’t miss an opportunity.
                <br />
                <br />
                Thewhitelist app’s customization is enabled not through a UI but
                by working directly with a development team to handle the
                customization from the back end. Enrollment to this service is
                currently closed at this time.
              </h4>

              {/** 
                  <button className="button-lg type-ui  bg-white text-wall-600">
                    Join the waitlist
                  </button>
                   */}

              {/** 
                  <iframe className="pb-8" width="100%" height="300" src="https://www.youtube.com/embed/dQw4w9WgXcQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  */}
            </div>
          </div>
        </Section>
        {/** 
            <Terms />
            */}
      </SingleColumn>
      <Footer></Footer>
    </Container>
  );
}
