import React from "react";
import Container from "./Container";
import Header from "./Header";
import Footer from "./Footer";
import Section from "./Section";
import SingleColumn from "./SingleColumn";

export default function Privacy() {
  return (
    <Container>
      <Header />

      <SingleColumn>
        <Section className=" md:flex">
          <iframe
            id="0565b6c0-6f74-48df-a5d8-957c1e43ae3a"
            title="Privacy policy"
            src="https://app.termly.io/embed/terms-of-use/0565b6c0-6f74-48df-a5d8-957c1e43ae3a"
            class="Iframe-iframe-ad8182"
            scrolling="no"
            allowfullscreen=""
            style={{ height: "9000px", opacity: 1 }}
            frameborder="0"
          >
            <p>Your browser does not support iframes.</p>
          </iframe>
        </Section>
      </SingleColumn>
      <Footer></Footer>
    </Container>
  );
}
